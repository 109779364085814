import React from "react"
import Parser from "html-react-parser"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"

import Section from "../elements/Section"
import Container from "../elements/Container"
import Share from "./share"
import globalVariables from "../globalVariables"
import Horloge from "../../images/icons/horloge-bleu.svg"
import Expert from "../../images/icons/expert-bleu.svg"
import Debutant from "../../images/icons/debutant-bleu.svg"

const Intro = styled.p`
  font-weight: bold;
`

const Body = styled.div`
  p,
  li,
  span {
    line-height: 1.4;
    /* text-align: justify; */
  }
  a {
    color: ${props => props.theme.primary};
    text-decoration: underline;
  }
  ul {
    padding-bottom: 1rem;
    padding-left: 2rem;
  }
  h2,
  h3,
  h4,
  h5 {
    margin-top: 2rem;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  img {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    height: 100%;
  }
  div {
    margin-bottom: 1rem;
  }
`
const Title = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${props => props.theme.greyLighter};
  padding: 0 1rem;
  transition: all 0.2s ease-in-out;
  p {
    font-size: 20px;
    font-weight: bold;
    max-width: 460px;
  }
`

const LinkRow = styled(Link)`
  display: none;
  @media (min-width: ${globalVariables.minTablet}) {
    text-decoration: none;
    height: 105px;
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 3rem;
    color: ${props => props.theme.bodyColor};

    &:hover ${Title} {
      color: ${props => props.theme.primary};
    }
  }
`

const Text = styled.div`
  flex: 0 1 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.primary};
  p {
    color: white;
    margin: 0;
    font-size: 22px;
    font-weight: bold;
  }
`
const Image = styled.div`
  flex: 0 1 165px;
  position: relative;
`

const Theme = styled.span`
  color: white;
  background: rgba(81, 85, 93, 0.7);
  padding: 5px 10px;
  min-width: 80px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  position: absolute;
  top: 10px;
  left: 0;
  @media (min-width: ${globalVariables.minTablet}) {
    top: 20px;
    padding: 5px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 165px;
`

const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.greyDarker};
  color: ${props => props.theme.white};
  flex-basis: 50%;
  font-size: 14px;
`

const Time = styled.div`
  border-left: 1px solid white;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LevelWrapper = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 15px;
`

const HorlogeStyled = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 15px;
  margin-right: 11px;
`

const Button = styled.div`
  background-color: ${props => props.theme.primary};
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${props => props.theme.greyDarker};
  }
`

const ArticleContent = ({ intro, text1, text2, nextArticle, path, title }) => {
  return (
    <Section padding="45px 0">
      <Container>
        <Intro>{intro}</Intro>
        <Body>{text1 ? Parser(text1) : null}</Body>
        {nextArticle && (
          <LinkRow to={"/blog" + nextArticle.node.path}>
            <Text>
              <p>À lire aussi&nbsp;:</p>
            </Text>
            <Image>
              <Img
                fluid={
                  nextArticle.node.acf.vignette_article.localFile
                    .childImageSharp.fluid
                }
              />
              <Theme>{nextArticle.node.acf.theme_article}</Theme>
            </Image>
            <Title>
              <p>{nextArticle.node.acf.titre_article}</p>
            </Title>
            <InfoWrapper>
              <Info>
                <LevelWrapper
                  src={
                    nextArticle.node.acf.niveau_article === "Expert"
                      ? Expert
                      : Debutant
                  }
                  alt="niveau"
                />
                <Time>
                  <HorlogeStyled src={Horloge} alt="temps de lecture" />
                  {nextArticle.node.acf.temps_de_lecture} min
                </Time>
              </Info>
              <Button>Lire la suite</Button>
            </InfoWrapper>
          </LinkRow>
        )}
        <Body>{text2 ? Parser(text2) : null}</Body>
        <Share path={path} title={title} />
      </Container>
    </Section>
  )
}

export default ArticleContent
