import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import Section from "../elements/Section"
import Container from "../elements/Container"
import { TitleH2 } from "../elements/Title"
import Button from "../elements/Button"
import globalVariables from "../globalVariables"

const Wrapper = styled.div`
  position: ${props => (!props.blueCover ? "absolute" : null)};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: ${props =>
    props.blueCover ? props.theme.primary : "rgba(80, 11, 117, 0.5)"};
  height: ${props => (props.blueCover ? props.imageMobileHeight : null)};
  @media (min-width: ${globalVariables.minTablet}) {
    height: ${props => (props.blueCover ? props.imageHeight : null)};
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const Text = styled.p`
  color: ${props => props.theme.white};
  margin-top: 8px;
  margin-bottom: 3rem;
  max-width: 740px;
`

const BlocCTA = ({
  title,
  description,
  imageBanner,
  imageHeight,
  imageMobileHeight,
  blueCover,
}) => {
  return (
    <Section
      padding="0"
      imageHeight={imageHeight}
      imageMobileHeight={imageMobileHeight}
    >
      {!blueCover && <Img fluid={imageBanner} />}

      <Wrapper
        blueCover={blueCover}
        imageHeight={imageHeight}
        imageMobileHeight={imageMobileHeight}
      >
        <Container>
          <Content>
            <TitleH2 className="title1" color="white">
              {title}
            </TitleH2>
            <Text>{description}</Text>
            <Button
              title="Nos livres blancs"
              colors={blueCover ? "primary" : "primaryWithBg"}
              to="/livres-blancs"
              as="link"
            />
          </Content>
        </Container>
      </Wrapper>
    </Section>
  )
}

export default BlocCTA
