import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Section from "../../elements/Section"
import Container from "../../elements/Container"
import { TitleH2 } from "../../elements/Title"
import Button from "../../elements/Button"
import globalVariables from "../../globalVariables"
import Card from "./Card"
import { NextArrow, PrevArrow } from "../../elements/ArrowCarousel"

const WrapperSlider = styled.div`
  .slick-arrow.slick-next {
    transform: rotate(180deg);
    top: auto;
    bottom: -4.5rem;
    right: 0rem;
  }
  .slick-arrow.slick-prev {
    top: auto;
    bottom: -4.5rem;
    left: 0rem;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    margin-top: 50px;
  }
`

const WrapperButton = styled.div`
  text-align: center;
  margin-bottom: 0;
  margin-top: 1.5rem;
  @media (min-width: ${globalVariables.minTablet}) {
    margin-top: 35px;
    margin-bottom: 25px;
  }
`

const Blog = ({ articles, title }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: articles.length < 3 ? articles.length : 3,
    slidesToScroll: 0,
    initialSlide: 0,
    nextArrow: <NextArrow color />,
    prevArrow: <PrevArrow color />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Section overflow="hidden" paddingMobile="35px 0 40px">
      <Container>
        <TitleH2 className="title1" position="center">
          {title}
        </TitleH2>
        <WrapperSlider>
          <Slider {...settings}>
            {articles.map((article, index) => (
              <Card data={article} key={index} prefix="/blog" />
            ))}
          </Slider>
        </WrapperSlider>
        <WrapperButton>
          <Button
            title="Tous les articles"
            colors="secondaryWithBg"
            to="/blog"
            as="link"
          />
        </WrapperButton>
      </Container>
    </Section>
  )
}

export default Blog
