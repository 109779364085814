import React from "react"
import styled from "styled-components"

import Section from "../elements/Section"
import Container from "../elements/Container"
import { TitleH2 } from "../elements/Title"
import globalVariables from "../globalVariables"
import Button from "../elements/Button"
import metiersData from "../../utils/metierData"

const Wrapper = styled.div`
  min-height: 300px;
  display: flex;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  margin-top: 3rem;
  align-items: center;
  text-align: center;
  @media (min-width: ${globalVariables.minTablet}) {
    justify-content: center;
    margin-top: 0;
  }
`

const Text = styled.p`
  color: ${props => props.theme.white};
  margin-top: 0;
  margin-bottom: 2rem;
  max-width: 570px;
`

const Help = ({ title, description, theme }) => {
  const getUrl = theme => {
    switch (theme) {
      case metiersData.SEO:
        return "/expertise/agence-seo/"
      case metiersData.SEA:
        return "/expertise/agence-sea/"
      case metiersData.SOCIAL_ADS:
        return "/expertise/agence-social-ads/"
      case metiersData.WEB_ANALYTICS:
        return "/expertise/agence-web-analytics/"
      case metiersData.UX:
        return "/expertise/agence-ux/"
      case metiersData.CRO:
        return "/expertise/agence-optimisation-conversion/"
      case metiersData.UX:
        return "/expertise/agence-ux/"
      case metiersData.DATA:
        return "/expertise/agence-data-marketing/"
      default:
        return ""
      // code block
    }
  }

  return (
    <Section padding="0" color="primary">
      <Wrapper>
        <Container>
          <Content>
            <TitleH2 className="title1" color="white">
              {title}
            </TitleH2>
            <Text>{description}</Text>
            <Button
              title="Découvrez nos prestations"
              colors="secondaryWithBg"
              to={getUrl(theme)}
              as="link"
            />
          </Content>
        </Container>
      </Wrapper>
    </Section>
  )
}

export default Help
