import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import globalVariables from "../globalVariables"

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 18px;
  background: ${props => props.theme.greyLighter};
  width: 150%;
`

const SocialLink = styled.a`
  width: 48px;
  height: 48px;
  margin-right: 20px;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: rotate(360deg);
  }
`

const Text = styled.p`
  display: none;
  @media (min-width: ${globalVariables.minTablet}) {
    display: block;
    font-weight: bold;
    margin-bottom: 0;
    margin-right: 2rem;
    font-size: 22px;
  }
`

const Share = ({ path, title }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        fbk: file(relativePath: { eq: "footer/fbk.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        lkd: file(relativePath: { eq: "footer/lkd.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        twt: file(relativePath: { eq: "footer/twt.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Wrapper>
      <Text>Partager</Text>
      <SocialLink
        href={`https://www.facebook.com/sharer.php?u=${data.site.siteMetadata.siteUrl}/blog${path}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Img fluid={data.fbk.childImageSharp.fluid} />
      </SocialLink>
      <SocialLink
        href={`https://twitter.com/intent/tweet?url=${data.site.siteMetadata.siteUrl}/blog${path}&text=${title}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Img fluid={data.twt.childImageSharp.fluid} />
      </SocialLink>
      <SocialLink
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${data.site.siteMetadata.siteUrl}/blog${path}&title=${title}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Img fluid={data.lkd.childImageSharp.fluid} />
      </SocialLink>
    </Wrapper>
  )
}

export default Share
