import React from "react"
import styled from "styled-components"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import theme from "../../theme"
import globalVariables from "../../globalVariables"

import LeftCardArticle from "./LeftCardArticle"
import RightCardArticle from "./RightCardArticle"

const Section = styled.div`
  background-color: ${theme.secondaryDarkPurple};
  padding-top: 71px;
  @media (max-width: ${globalVariables.maxMobile}) {
    padding-top: 160px;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding-bottom: 60px;
  padding-left: 50px;
  @media (max-width: ${globalVariables.maxMobile}) {
    padding-bottom: 50px;
    padding-left: 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const BannerV2Article = ({ bannerLeftCardContent }) => {
  return (
    <Section>
      <Wrapper>
        <ContentWrapper>
          <LeftCardArticle content={bannerLeftCardContent} />
          <RightCardArticle />
        </ContentWrapper>
      </Wrapper>
    </Section>
  )
}

export default BannerV2Article
