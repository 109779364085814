const metiersData = Object.freeze({
  SEO: "SEO",
  SEA: "SEA",
  SOCIAL_ADS: "Social Ads",
  WEB_ANALYTICS: "Web Analytics",
  UX: "UX",
  CRO: "CRO",
  DATA: "Data",
})

export default metiersData
