import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleContent from "../components/Article/"
import Help from "../components/Article/help"
import { default as LastBlog } from "../components/Home/Blog"
import Blog from "../components/Metiers/blog"
import BlocCTA from "../components/Ebooks/BlocCTA"
import Contact from "../components/Home/Contact"
import { getDateFormat } from "../utils/getDateFormat"
import BannerV2Article from "../components/elements/BannersV2/BannerV2Article"

const Article = ({ data, location }) => {
  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        categoryPage: data.article.acf.theme_article,
        articleType: data.article.acf.type_article,
        level: data.article.acf.niveau_article,
        duration: data.article.acf.temps_de_lecture,
        contentLength: "non définie",
        datePublication: data.article.acf.date_de_publication,
      })
    } else {
      window.dataLayer = []
      window.dataLayer.push({
        categoryPage: data.article.acf.theme_article,
        articleType: data.article.acf.type_article,
        level: data.article.acf.niveau_article,
        duration: data.article.acf.temps_de_lecture,
        contentLength: "non définie",
        datePublication: data.article.acf.date_de_publication,
      })
    }
  }, [])
  const getNextArticle = data => {
    const filteredPost = data.filter(
      item =>
        item.node.acf.theme_article === article.acf.theme_article &&
        item.node.path !== article.path
    )

    return filteredPost[Math.floor(Math.random() * filteredPost.length)]
  }

  const { article, articles, bloc_ebook_cta, bloc_contact } = data

  const bannerLeftCardContent = {
    theme: article.acf.theme_article,
    type: article.acf.type_article,
    title: article.acf.title,
    publicationDate: article.acf.date_de_publication,
    readLength: article.acf.temps_de_lecture,
    level: article.acf.niveau_article,
  }

  return (
    <Layout
      location="/blog"
      locationBis={location.pathname}
      crumbLabel="Ressources"
      crumbLabelBis={article.acf.title}
      locationGTM={location.href}
    >
      <SEO
        title={article.acf.title}
        description={article.acf.meta_description}
        image={article.acf.image_article.localFile.childImageSharp.fluid.src}
        lang="fr"
        url={location.pathname}
        isBlogPost
        datePublished={article.acf.date_de_publication}
      />
      <BannerV2Article bannerLeftCardContent={bannerLeftCardContent} />
      <ArticleContent
        intro={article.acf.intro_article}
        text1={article.acf.texte_1}
        text2={article.acf.texte_2}
        nextArticle={getNextArticle(articles.edges)}
        path={article.path}
        title={article.acf.titre_article}
      />
      <Help
        title={article.acf.titre_bloc_besoin_daide}
        description={article.acf.texte_bloc_besoin_daide}
        theme={article.acf.theme_article}
      />
      <LastBlog
        articles={articles.edges
          .filter(
            item =>
              item.node.path !== article.path &&
              item.node.acf.theme_article === article.acf.theme_article
          )
          .sort(
            (a, b) =>
              getDateFormat(b.node.acf.date_de_publication) -
              getDateFormat(a.node.acf.date_de_publication)
          )
          .slice(0, 3)}
        title="Articles similaires"
      />
      <BlocCTA
        title={bloc_ebook_cta.acf.titre_ebook}
        description={bloc_ebook_cta.acf.texte_ebook}
        imageBanner={data.bannerEbook.childImageSharp.fluid}
        imageHeight="350px"
        imageMobileHeight="426px"
      />
      <Blog
        items={articles.edges
          .filter(item => item.node.path !== article.path)
          .sort(
            (a, b) =>
              getDateFormat(b.node.acf.date_de_publication) -
              getDateFormat(a.node.acf.date_de_publication)
          )
          .slice(0, 4)}
      />
      <Contact
        title={bloc_contact.acf.titre_contact}
        text={bloc_contact.acf.texte_contact}
        location={location.href}
      />
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($id: String!) {
    article: wordpressPost(id: { eq: $id }) {
      path
      acf {
        title
        meta_description
        # image
        type_article
        theme_article
        niveau_article
        titre_article
        intro_article
        date_de_publication
        texte_1
        texte_2
        image_article {
          localFile {
            childImageSharp {
              fluid(quality: 100, srcSetBreakpoints: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        temps_de_lecture
        titre_bloc_besoin_daide
        texte_bloc_besoin_daide
      }
    }
    bloc_ebook_cta: wordpressAcfBlocs(
      id: { eq: "a3c88e12-40c2-5421-a689-7af9b4a67e10" }
    ) {
      acf {
        titre_ebook
        texte_ebook
      }
    }
    bannerEbook: file(relativePath: { eq: "ebooks/e-book-2.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    articles: allWordpressPost {
      edges {
        node {
          path
          acf {
            type_article
            theme_article
            niveau_article
            titre_article
            intro_article
            date_de_publication
            vignette_article {
              localFile {
                childImageSharp {
                  fluid(srcSetBreakpoints: 400, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            temps_de_lecture
          }
        }
      }
    }
    bloc_contact: wordpressAcfBlocs(
      id: { eq: "76393d63-8408-5238-b201-7f73d6fe7a78" }
    ) {
      acf {
        titre_contact
        texte_contact
      }
    }
  }
`
