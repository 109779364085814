import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import globalVariables from "../../globalVariables"
import Horloge from "../../../images/icons/horloge-bleu.svg"
import Expert from "../../../images/icons/expert-bleu.svg"
import Debutant from "../../../images/icons/debutant-bleu.svg"
import { TitleH3 } from "../../elements/Title"

const CardStyled = styled.a`
  display: block;
  z-index: 1;
  text-decoration: none;
  @media (min-width: ${globalVariables.minTablet}) {
    flex: 0 0 50%;
    .gatsby-image-wrapper {
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      .gatsby-image-wrapper {
        transform: scale(1.1);
      }
    }
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    flex: 0 0 33.33%;
  }
`

const CardContainer = styled.div`
  margin: 10px 0 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (min-width: ${globalVariables.minTablet}) {
    margin: 10px 10px 22px 10px;
  }
`

const CardItem = styled.div`
  flex: 1 1 100%;
`
const CardContent = styled.div`
  flex: 1 1 100%;
  padding: 22px;
  border-right: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  height: 192px;
  .title4 {
    font-size: 20px;
    overflow: hidden;
    position: relative;
    line-height: 1.2em;
    max-height: 2.8rem;
    margin-right: -10px;
    padding-right: 1em;
    &:before {
      content: "...";
      position: absolute;
      right: 0;
      bottom: 0;
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      width: 1em;
      height: 1em;
      margin-top: 0.2em;
      background: white;
    }
  }
`

const ImageWrapper = styled.div`
  height: 200px;
  position: relative;
  overflow: hidden;
`

const Category = styled.span`
  color: white;
  background: ${props => props.theme.secondary};
  opacity: 0.7;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  position: absolute;
  left: 0;
  min-width: 110px;
  top: 20px;
  padding: 5px;
`

const DateWrapper = styled.div`
  background: ${props => props.theme.secondary};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  p {
    margin: 0;
    font-size: 14px;
    color: white;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Time = styled.div`
  border-left: 1px solid white;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LevelWrapper = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 15px;
`
const HorlogeStyled = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 15px;
  margin-right: 11px;
`

const Text = styled.p`
  margin-top: -10px;
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 4.8em;
  text-align: justify;
  margin-right: -10px;
  padding-right: 1em;
  color: ${props => props.theme.bodyColor};
  &:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
`

const Card = ({ data, prefix }) => {
  return (
    <CardStyled href={prefix ? prefix + data.node.path : data.node.path}>
      <CardContainer>
        <CardItem>
          <ImageWrapper>
            <Img
              fluid={
                data.node.acf.vignette_article.localFile.childImageSharp.fluid
              }
            />
            <Category>{data.node.acf.theme_article}</Category>
          </ImageWrapper>
        </CardItem>
        <CardItem>
          <DateWrapper>
            <p>{data.node.acf.date_de_publication}</p>
            <Info>
              <LevelWrapper
                src={
                  data.node.acf.niveau_article === "Expert" ? Expert : Debutant
                }
                alt="niveau"
              />
              <Time>
                <HorlogeStyled src={Horloge} alt="temps de lecture" />
                <p>{data.node.acf.temps_de_lecture} min</p>
              </Time>
            </Info>
          </DateWrapper>
        </CardItem>
        <CardContent>
          <TitleH3 className="title4">{data.node.acf.titre_article}</TitleH3>
          <Text>{data.node.acf.intro_article}</Text>
        </CardContent>
      </CardContainer>
    </CardStyled>
  )
}

export default Card
