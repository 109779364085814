import React from "react"
import styled from "styled-components"

import globalVariables from "../../globalVariables"
import theme from "../../theme"

import levelIcon from "../../../images/icons/left_card_level.svg"
import readDurationIcon from "../../../images/icons/left_card_read_duration.svg"
import publicationDateIcon from "../../../images/icons/left_card_publication_date.svg"

const Content = styled.div`
  width: 572px;
  z-index: 1;
  @media (max-width: ${globalVariables.maxMobile}) {
    padding-left: 40px;
  }
`

const WrapperTag = styled.div`
  display: flex;
  .theme {
    border: 2px solid ${theme.green};
    color: ${theme.green};
  }

  .type {
    border: 2px solid ${theme.primary};
    color: ${theme.primary};
  }
`

const Tag = styled.span`
  align-items: center;

  border-radius: 100px;
  display: flex;
  font-size: 14px;
  font-weight: 900;
  height: 30px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 1.3;
  text-align: center;
  min-width: 110px;

  &:first-of-type {
    margin-right: 15px;
  }
`

const Title = styled.h1`
  color: ${theme.white};
  width: 1067px;
  font-family: source sans pro;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0;
  margin-top: 20px;
  @media (max-width: ${globalVariables.burgerBreakpoint}) {
    margin: 20px 0;
    width: auto;
    font-size: 36px;
  }
`

const P = styled.p`
  display: flex;
  align-items: center;
  color: ${theme.white};
  font-family: source sans pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0;
  margin-bottom: 14px;
  span {
    color: ${theme.white};
    font-weight: 900;
  }
`

const Img = styled.img`
  color: #5d92ff;
  font-size: 35px;
  font-weight: 400;
  line-height: 1;
  height: 23px;
  margin-right: 15px;
  width: 23px;
`

const LeftCardArticle = ({
  content: { theme, type, title, publicationDate, readLength, level },
}) => {
  const months = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ]
  const dateArray = publicationDate.split("/")
  return (
    <>
      <Content>
        <WrapperTag>
          <Tag className="theme">{theme}</Tag>
          <Tag className="type">{type}</Tag>
        </WrapperTag>
        <Title>{title}</Title>
        <P>
          <Img src={publicationDateIcon} />
          <span>Date de publication :&nbsp;</span>
          {dateArray[0]} {months[Number(dateArray[1]) - 1]} {dateArray[2]}
        </P>
        {readLength ? (
          <P>
            <Img src={readDurationIcon} />
            <span>Temps de lecture :&nbsp;</span>
            {readLength} min.
          </P>
        ) : null}

        <P>
          <Img src={levelIcon} />
          <span>Niveau :&nbsp;</span>
          {level}
        </P>
      </Content>
    </>
  )
}

export default LeftCardArticle
