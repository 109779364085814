import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import globalVariables from "../globalVariables"
import Section from "../elements/Section"
import Title from "../elements/Title"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const Item = styled(Link)`
  display: block;
  text-decoration: none;
  height: 225px;
  position: relative;
  overflow: hidden;
  &:not(:last-child) {
    border-bottom: 1px solid white;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    flex: 0 0 50%;

    height: 330px;
    &:not(:last-child) {
      border-right: 1px solid white;
      border-bottom: 0;
    }
    .gatsby-image-wrapper {
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      .gatsby-image-wrapper {
        transform: scale(1.1);
      }
    }
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    flex: 0 0 25%;
  }
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props =>
    props.isHighlight ? "rgba(82, 145, 206, 0.8)" : "rgba(81, 85, 93, 0.8)"};
  padding: 0 50px 0 30px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .title1 {
    color: white;
  }

  @media (max-width: ${globalVariables.maxMobile}) {
    .title1 {
      font-size: 28px;
    }
    .title1.trait::after {
      width: 200px;
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 15px 10px;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    padding: 0 50px 0 40px;
  }
`

const Text = styled.p`
  font-weight: bold;
  color: white;
  margin-top: 20px;
  margin-bottom: 2rem;
  height: 40px;
`

const Blog = ({ items }) => {
  return (
    <Section padding="0">
      <Container>
        {items.map((item, index) => (
          <Item key={index} to={"/blog" + item.node.path}>
            <Img
              fluid={
                item.node.acf.vignette_article.localFile.childImageSharp.fluid
              }
            />
            <Content isHighlight={item.node.acf.type_article === "Actualité"}>
              <Title className="title1 trait">
                {item.node.acf.type_article}
              </Title>
              <Text>{item.node.acf.titre_article}</Text>
            </Content>
          </Item>
        ))}
      </Container>
    </Section>
  )
}

export default Blog
